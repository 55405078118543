import { genMuiThemes } from 'clnt-common';

const ltp    = '#d3bff1';   //36097d';   //0f1bc7';    // light color with red tint - nav bar/footer/submenubar bg color ALSO used in manifest.webmanifest: theme_color
const lts    = '#EF5350';    // button bg in light mode (darker purple) - secondary / also used in the manifest.webmanifest
const ltpnl  = '#ebe2f9';    // light color with darker red tint for panel - 22 biggest... section bg color
const ltcpnl = '#ebe2f9';    // light content panel -  (The same is used in manifest.webmanifest: background_color)

const dkp    = '#a88bd5';   //ebe2f9';   //0f1bc7';    //
const dks    = '#EFcd3f';    // button bg in dark mode (lighter purple) - secondary
const dkpnl  = '#232323';    // compliment to ltpnl
const dkcpnl = '#280e38';    // dark content panel

const options = {
  fontHeader :      'Albra',
  fontText :        'Inter',

  primaryLight:     ltp,
  secondaryLight:   lts,
  panelBgLight:     ltpnl,
  backpanelBgLight: ltp,
  contentpanelBgLight: ltcpnl,

  primaryDark:      dkp,
  secondaryDark:    dks,
  panelBgDark:      dkpnl,
  backpanelBgDark:  dkp,
  contentpanelBgDark:  dkcpnl,

  pagePadding : 1,
  pageGap : 0,

  // For use within pages.ts (rendered via PageContentFormat.jsx)
  // 5 sets of areas.
  areaLight: [{ panel: '', border: '#cccccc' },  // about
              { panel: '', border: '#3845f3' },  // service:matrix
              { panel: '#e8eafb', border: '' },  // service:chat
              { panel: '#af9d9d', border: '#cccffa' },  // service: *
              { panel: '', border: '' }],  
  areaDark:  [{ panel: '', border: '' },  
              { panel: '', border: '' },  
              { panel: '', border: '' },  
              { panel: '', border: '' },  
              { panel: '', border: '' }],

  borderLight : '#eeeeee',      // Used for FORM container border
  borderDark : '#777777',       // Used for FORM container border
};

export { themes };
let themes = genMuiThemes(options);


