import React from 'react';

import { colors } from '@mui/material';

// Icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ImageIcon from '@mui/icons-material/Image';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import HomeIcon from '@mui/icons-material/Home';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SettingsIcon from '@mui/icons-material/Settings';
import PageviewIcon from '@mui/icons-material/Pageview';
import StoreIcon from '@mui/icons-material/Store';
import ListIcon from '@mui/icons-material/List';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CreateIcon from '@mui/icons-material/Create';

export { SubmenubarList };
const SubmenubarList = (da, theme) => {

  const nav = da.getNav();
  //const isAuthenticated = da.isAuthenticated();

  const isAccountOwner = da.isAccountOwner();      // members, invites,     , users,
  const isAccountOwnerSubscriber = da.isAccountOwnerSubscriber();

  const buttonList = [
    {
      code: 'home',
      display: 'Home',
      bucket: {
	iconLeft: <HomeIcon />,
	tip: 'App home page',
      },
    },
  ];

  //if (isAuthenticated)
  buttonList.push({
    code: 'list',
    display: 'Library',
    bucket: {
      iconLeft: <LibraryBooksIcon />,
      tip: 'Library of all forms',
    },
  });

  //if (isAuthenticated)
  buttonList.push({
    code: 'build',
    display: 'Build',
    bucket: {
      iconLeft: <CreateIcon />,
      tip: 'Create/Update a form',
    },
  });

  //if (isAuthenticated)
  buttonList.push({
    code: 'preview',
    display: 'Preview',
    bucket: {
      iconLeft: <VisibilityIcon />,
      tip: 'Preview currently selected form',
    },
  });

  //if (isAuthenticated)
  buttonList.push({
    code: 'data',
    display: 'Submissions',
    bucket: {
      iconLeft: <ListIcon />,
      tip: 'View form submissions data',
    },
  });

  //if (da.isDev())
  //if (isAuthenticated)
  buttonList.push({
    code: 'market',
    display: 'Marketplace',
    bucket: {
      iconLeft: <StoreIcon />,
      tip: 'Marketplace forms',
    },
  });

  return buttonList;
}
